import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';
import router from './router';
import App from './App.vue';
import './assets/css/main.css';
import { WangsVue, ToastService } from 'wangsvue';
import Preset from 'wangsvue-presets/wangsvue';
import 'wangsvue/style.css';
import 'wangsvue-presets/wangsvue/style.css';
const vueLifecycles = singleSpaVue({
    createApp,
    appOptions: {
        render() {
            return h(App);
        },
    },
    handleInstance(app) {
        app.use(ToastService);
        app.use(router);
        app.use(WangsVue, {
            unstyled: true,
            pt: Preset,
        });
        app.provide('preset', Preset);
    },
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
