import API from './api.service';
const UserServices = {
    getDetailUser: (id) => {
        return API('settings').get(`/users/${id}`);
    },
    getDetailUserLog: (id, params) => {
        return API('settings', { params }).get(`/user-logs/${id}`);
    },
    getDetailUserLogOptions: (id, params) => {
        return API('settings', { params }).get(`/user-logs/${id}/options`);
    },
    putDigitalSignature: (body, id) => {
        const headers = { 'Content-Type': 'multipart/form-data' };
        return API('settings', { headers }).put(`/users/${id}/digital-signature`, body);
    },
};
export default UserServices;
