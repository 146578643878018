import { getBaseURL } from '@/utils/getBaseURL.util';
import axios from 'axios';
const API = (prefix, { headers = {}, params = {} } = {}) => {
    const user = JSON.parse(localStorage.getItem('user')) ?? {};
    const BASE_URL = getBaseURL('APP_SALES_API') ?? 'http://localhost:9090';
    const instance = axios.create({
        baseURL: `${BASE_URL}/v1/${prefix}`,
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${user.token}`,
            ...headers,
        },
        params,
    });
    return instance;
};
export default API;
